import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import logo from '../imgs/whiteLogo.svg';
import flag from '../imgs/cdn-flag.png';

import classes from 'styles/components/Footer.module.scss';

import TermsOfUseContent from './TermsOfUseContent';
import PrivacyPolicyContent from './PrivacyPolicyContent';

const Footer = () => {
  const { t } = useTranslation();
  const [openTOU, setOpenTOU] = useState(false);
  const [openPP, setOpenPP] = useState(false);

  const handleOpenTOU = () => setOpenTOU(true);
  const handleCloseTOU = () => setOpenTOU(false);

  const handleOpenPP = () => setOpenPP(true);
  const handleClosePP = () => setOpenPP(false);

  return (
    <AppBar className={classes.appBar} position="static">
      <Container>
        <Toolbar className={classes.toolBar}>
          <div className={classes.ftrLeft}>
            <div
              style={{
                display: `flex`,
                flexDirection: `column`,
                marginTop: `1rem`,
              }}
            >
              <img src={logo} style={{ margin: `.5rem 0 1rem` }} alt="Homewise" width="140" height="26.5" />
            </div>
            <small className={classes.ftrLegal}>
              <img src={flag} width="22" height="10" alt="Canadian Flag" /> {t('legal.0')}
            </small>
            <small className={classes.ftrLegal}>
              &copy; {new Date().getFullYear()} {t('legal.1')}
              <br />
              {t('legal.2')}
              <br />
              {t('legal.3')}
            </small>
          </div>

          <div className={classes.ftrRight}>
            <ul style={{ padding: `0`, margin: `0` }}>
              <li style={{ display: 'inline', padding: '0rem .75rem' }}>
                <Button
                  className={classes.link}
                  style={{
                    textDecoration: `none`,
                    background: `transparent`,
                    border: `0`,
                    cursor: `pointer`,
                    fontFamily: `Merriweather Sans`,
                    fontWeight: `300`,
                    padding: `0`,
                  }}
                  onClick={handleOpenTOU}
                >
                  {t('cta.terms')}
                </Button>
              </li>
              <li style={{ display: 'inline', padding: '0rem .75rem' }}>
                <Button
                  className={classes.link}
                  style={{
                    textDecoration: `none`,
                    background: `transparent`,
                    border: `0`,
                    cursor: `pointer`,
                    fontFamily: `Merriweather Sans`,
                    fontWeight: `300`,
                    padding: `0`,
                  }}
                  onClick={handleOpenPP}
                >
                  {t('cta.privacy')}
                </Button>
              </li>
              <li style={{ display: `inline`, padding: `0rem .75rem` }}>
                <Button
                  className={classes.link}
                  id="accessibilityWidget"
                  style={{
                    textDecoration: `none`,
                    background: `transparent`,
                    border: `0`,
                    cursor: `pointer`,
                    fontFamily: `Merriweather Sans`,
                    fontWeight: `300`,
                    padding: `0`,
                  }}
                >
                  {t('cta.accessible')}
                </Button>
              </li>
              <li style={{ display: 'none', padding: `0rem .75rem` }}>
                <Button
                  className={classes.link}
                  id="accessibilityWidget1"
                  style={{
                    textDecoration: `none`,
                    background: `transparent`,
                    border: `0`,
                    cursor: `pointer`,
                    fontFamily: `Merriweather Sans`,
                    fontWeight: `300`,
                    padding: `0`,
                  }}
                >
                  {t('cta.accessible')}
                </Button>
              </li>
            </ul>
          </div>
        </Toolbar>
      </Container>
      <Modal open={openTOU} onClose={handleCloseTOU}>
        <Box className={classes.modalBox}>
          <div className={classes.modalContent}>
            <div className={classes.modalTitle}>
              <h2>Terms of Use</h2>
              <IconButton onClick={handleCloseTOU}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.modalBody}>
              <TermsOfUseContent />
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={openPP} onClose={handleClosePP}>
        <Box className={classes.modalBox}>
          <div className={classes.modalContent}>
            <div className={classes.modalTitle}>
              <h2>Privacy Policy</h2>
              <IconButton onClick={handleClosePP}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.modalBody}>
              <PrivacyPolicyContent />
            </div>
          </div>
        </Box>
      </Modal>
    </AppBar>
  );
};

export default Footer;
