import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function HWCircularProgress(props) {
  return (
    <div style={{ position: 'relative', textAlign: `center` }}>
      <CircularProgress variant="determinate" sx={{ color: '#ffffff' }} size={24} thickness={4} {...props} value={100} />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{ color: '#007bff', animationDuration: '550ms', position: 'absolute', left: `calc(50% - 12px)` }}
        classes={{
          circle: {
            strokeLinecap: 'round',
          },
        }}
        size={24}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export default function LinearBuffer() {
  return (
    <div style={{ width: '100%', padding: `1rem 0`, textAlign: `center` }}>
      <HWCircularProgress />
    </div>
  );
}
