import React, { useContext, lazy, Suspense } from 'react';
import UserContext from '../store/app/Context';
import { store } from '../store/app/Store';
import i18next from '../i18n';
import Config from '../config';

import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Toolbar, Button, Container } from '@mui/material';
import { Box } from '@mui/material';

import logoHouse from '../imgs/homewise-house.png';
import logo from '../imgs/homewise-logo.png';
import leaf from '../imgs/cdn-leaf.png';

import { withRouter, Link } from 'react-router-dom';
import { isSessionActive, setSessionInactive } from 'utils/session';

import styles from '../styles/Nav.module.scss';

const MobileNavLazy = lazy(() => import('components/MobileNav'));

const HeaderImage = (props) => {
  const globalState = useContext(store);

  const disabledCobrand = Config.optOut;

  return (
    <>
      {globalState.state.isPartnerValid ? (
        globalState.state.partner.business ? (
          globalState.state.partner.branding.logo ? (
            <Link to={`${globalState.state.partnerPath}`} style={{ display: `flex`, alignSelf: `center` }}>
              <img src={logo} className={styles.logo} alt="Homewise" height="25" width="131.47" />
              {!disabledCobrand.includes(globalState.state.partner.business.name) && (
                <img src={globalState.state.partner.branding.logo} className={styles.logo} alt="" height="25" />
              )}
            </Link>
          ) : globalState.state.partner.business.name ? (
            <Link to={`${globalState.state.partnerPath}`} className={styles.businessNameLink}>
              <Box
                component="img"
                src={logo}
                className={styles.logo}
                alt="Homewise"
                sx={{
                  height: '25px',
                  width: '131.47px',
                  display: { xs: 'none', sm: 'block' },
                }}
              />
              <Box
                component="img"
                src={logoHouse}
                className={styles.logo}
                alt="Homewise"
                sx={{ height: '25px', display: { xs: 'block', sm: 'none' } }}
              />
              {!disabledCobrand.includes(globalState.state.partner.business.name) && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: globalState.state.partner.business.name,
                  }}
                  style={{ textDecoration: 'none', paddingLeft: '15px' }}
                />
              )}
            </Link>
          ) : (
            <Link to={`${globalState.state.partnerPath}`} style={{ display: `flex`, alignSelf: `center` }}>
              <img src={logo} className={styles.logo} alt="Homewise" height="25" />
            </Link>
          )
        ) : (
          <Link to={`${globalState.state.partnerPath}`} style={{ display: `flex`, alignSelf: `center` }}>
            <img src={logo} className={styles.logo} alt="Homewise" height="25" />
          </Link>
        )
      ) : (
        <Link to={'/'} style={{ display: `flex`, alignSelf: `center` }}>
          <img src={logo} className={styles.logo} alt="Homewise" height="25" />
        </Link>
      )}
    </>
  );
};

class Nav extends React.Component {
  static contextType = UserContext;

  render() {
    const { dispatch } = this.props;

    const { auth, endSession } = this.context;

    const Login = withRouter(({ history }) => (
      <Button
        className={styles.button}
        component="a"
        href={Config.hud}
        target="_blank"
        rel="noopener noreferrer"
        variant={'outlined'}
        color="secondary"
      >
        {i18next.t('common:cta.signin')}
      </Button>
    ));

    const SignOut = withRouter(({ history }) => (
      <Button
        className={styles.button}
        onClick={() => {
          endSession(() => {
            // history.go(0);
            setSessionInactive();
            if (this.props.fromController) {
              this.props.setActiveStep(null);
            }
            dispatch({ type: 'LOG_OUT' });
            history.push('../');
          });
        }}
        variant={'outlined'}
        color="secondary"
      >
        Sign Out
      </Button>
    ));

    // const Dashboard = withRouter(({ history }) => (
    //   <Button
    //     onClick={() => {
    //       history.push('/dashboard');
    //     }}
    //     variant={'text'}
    //     color="secondary"
    //     className={this.props.classes.button}
    //     style={{ marginRight: `.5rem` }}
    //   >
    //     Dashboard
    //   </Button>
    // ));

    // const activeSession = localStorage.getItem('activeSession') === 'true';
    // console.log("LocalStorageSession", activeSession);

    return (
      <>
        <AppBar position="fixed" className={styles.appBar}>
          <Container disableGutters className={styles.flexContainer}>
            {this.props.showFlag && (
              <Box
                component="img"
                src={leaf}
                alt="Across Canada"
                sx={{
                  height: '20px',
                  width: '20px',
                  marginLeft: `1rem`,
                  alignSelf: `center`,
                  display: { xs: 'block', sm: 'none', md: 'none' },
                }}
              />
            )}
            <HeaderImage classes={this.props.classes} t={this.props.t} />

            <Toolbar className={styles.toolBar} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
              {isSessionActive() || auth.activeSession ? (
                <>
                  {/* <Dashboard /> */}
                  <SignOut />
                </>
              ) : (
                <Login />
              )}
              {this.props.showFlag && (
                <img src={leaf} alt="Across Canada" width="20" height="20" style={{ marginTop: `-.2rem`, marginLeft: `1rem` }} />
              )}
            </Toolbar>

            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
              <Suspense
                fallback={
                  <Button style={{ padding: `10px` }} aria-label={'Menu'}>
                    <MenuIcon color="primary" />
                  </Button>
                }
              >
                <MobileNavLazy activeSession={isSessionActive()} authSession={auth.activeSession} />
              </Suspense>
            </Box>
          </Container>
        </AppBar>
        <Toolbar className={styles.toolBar2} />
      </>
    );
  }
}

export default Nav;
