import React from 'react';
import cmsData from '../cmsData.json';

export default function PrivacyContent() {
  if (!cmsData) {
    console.error('Error: cmsData.json could not be found or loaded.');
    return <div>Privacy information is currently unavailable.</div>;
  }

  const { privacy } = cmsData;

  if (!Array.isArray(privacy)) {
    console.error(`Error: Expected 'privacy' to be an array, but received ${typeof privacy}.`);
    return <div>Privacy information is currently unavailable.</div>;
  }

  if (privacy.length === 0) {
    console.warn('Warning: The privacy array is empty.');
    return <div>No privacy information available at this time.</div>;
  }

  return (
    <div>
      {privacy.map((page) =>
        page.title && page.body ? (
          <div key={page.title}>
            <div dangerouslySetInnerHTML={{ __html: page.body }} />
          </div>
        ) : (
          <div key={page.title || Math.random()}>
            <p>Invalid privacy page data.</p>
          </div>
        )
      )}
    </div>
  );
}
