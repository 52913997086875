import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';

import { base } from 'utils/routes/base';
import { partner } from 'utils/routes/partner';
import { advisor } from 'utils/routes/advisor';
import { realtor } from 'utils/routes/realtor';
import { realty } from 'utils/routes/realty';
import { customer } from 'utils/routes/customer';
import { marketing } from 'utils/routes/marketing';
import { ma } from 'utils/routes/ma';
import { e } from 'utils/routes/employees';

import Config from '../config';

import { StateProvider } from '../store/app/Store';
import { UserProvider } from '../store/app/Provider';
import { DashboardProvider } from '../store/dashboard/Provider';

// import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
// import { MuiThemeProvider, createTheme } from '@mui/material/styles';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import NotFound from './NotFound';
import App from './App';
import Landing from '../pages/Landing/Landing';

import PrivateRoute from './PrivateRoute';
import ScrollToTop from './ScrollToTop';
import Loading from './Loading2';

import TagManager from 'react-gtm-module';

import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();

const DashboardLazy = lazy(() => import('pages/Dashboard/Dashboard'));
const StatusLazy = lazy(() => import('pages/Dashboard/Status'));
const ReferralsLazy = lazy(() => import('pages/Dashboard/Referrals'));
const DocumentsLazy = lazy(() => import('pages/Dashboard/Documents'));
const LoginLazy = lazy(() => import('pages/Auth/Login'));
const RegisterLazy = lazy(() => import('pages/Auth/Register'));
const ResetPasswordLazy = lazy(() => import('pages/Auth/ResetPassword'));
const VerifyEmailLazy = lazy(() => import('pages/Auth/Verify'));
const ApplicationsLazy = lazy(() => import('pages/Dashboard/Applications'));
const MortgagePaymentCalculatorLazy = lazy(() => import('pages/Dashboard/tools/MortgagePaymentCalculator'));
const ClosingCostsCalculatorLazy = lazy(() => import('pages/Dashboard/tools/ClosingCostsCalculator'));
const AffordabilityCalculatorLazy = lazy(() => import('pages/Dashboard/tools/AffordabilityCalculator'));

const tagManagerArgs = {
  gtmId: Config.google.GTM_ID,
  auth: Config.google.GTM_AUTH,
  preview: Config.google.GTM_PREV,
};

if (process.env.NODE_ENV !== 'development') {
  TagManager.initialize(tagManagerArgs);
}

// https://coolors.co/326822-ccefc4-007bff-147bc9-054d85
// https://coolors.co/ffffff-f5f7fa-ccefc4-b0e5f8-fdd4d0
// https://coolors.co/406236-ccefc4-3b98fb-3c8cc9-205680

const theme = createTheme({
  root: { '& svg:first-child': { transform: 'scale(1)' } },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Merriweather Sans', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    body1: {
      fontSize: '.9rem',
      // color: `#054d85`,
    },
    h5: {
      fontFamily: ['Merriweather', 'serif'].join(','),
    },
  },
  palette: {
    primary: {
      light: '#007bff',
      main: '#147bc9',
      dark: '#054d85',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ccefc4',
      main: '#326822',
      dark: '#264f1a',
      contrastText: '#fff',
    },
  },
  AppBar: {
    boxShadow: 'none',
  },
});

export default class Router extends React.Component {
  render() {
    const baseRoutes = base.map(({ path, isEmbed, options }) => (
      <Route
        key={'key'} // Set the same key so we dont remount the component on every route change
        exact
        path={path}
        render={(props) => <App {...props} isEmbed={isEmbed} options={options} pymChild={this.props.pymChild} />}
      />
    ));

    const partnerRoutes = partner.map(({ path, isEmbed, options }) => (
      <Route
        key={'key'} // Set the same key so we dont remount the component on every route change
        exact
        path={path}
        render={(props) => <App {...props} group={'partner'} isEmbed={isEmbed} options={options} pymChild={this.props.pymChild} />}
      />
    ));

    const advisorRoutes = advisor.map(({ path, isEmbed, options }) => (
      <Route
        key={'key'} // Set the same key so we dont remount the component on every route change
        exact
        path={path}
        render={(props) => <App {...props} group={'advisor'} isEmbed={isEmbed} options={options} pymChild={this.props.pymChild} />}
      />
    ));

    const maRoutes = ma.map(({ path, isEmbed, options }) => (
      <Route
        key={'key'} // Set the same key so we dont remount the component on every route change
        exact
        path={path}
        render={(props) => <App {...props} group={'ma'} isEmbed={isEmbed} options={options} pymChild={this.props.pymChild} />}
      />
    ));

    const eRoutes = e.map(({ path, isEmbed, options }) => (
      <Route
        key={'key'} // Set the same key so we dont remount the component on every route change
        exact
        path={path}
        render={(props) => <App {...props} group={'e'} isEmbed={isEmbed} options={options} pymChild={this.props.pymChild} />}
      />
    ));

    const realtorRoutes = realtor.map(({ path, isEmbed, options }) => (
      <Route
        key={'key'} // Set the same key so we dont remount the component on every route change
        exact
        path={path}
        render={(props) => <App {...props} group={'realtor'} isEmbed={isEmbed} options={options} pymChild={this.props.pymChild} />}
      />
    ));

    const realtyRoutes = realty.map(({ path, isEmbed, options }) => (
      <Route
        key={'key'} // Set the same key so we dont remount the component on every route change
        exact
        path={path}
        render={(props) => <App {...props} group={'ra'} isEmbed={isEmbed} options={options} pymChild={this.props.pymChild} />}
      />
    ));

    const customerRoutes = customer.map(({ path, isEmbed, options }) => (
      <Route
        key={'key'} // Set the same key so we dont remount the component on every route change
        exact
        path={path}
        render={(props) => <App {...props} group={'customer'} isEmbed={isEmbed} options={options} pymChild={this.props.pymChild} />}
      />
    ));

    const marketingRoutes = marketing.map(({ path, isEmbed, options }) => (
      <Route
        key={'key'} // Set the same key so we dont remount the component on every route change
        exact
        path={path}
        render={(props) => <App {...props} group={'marketing'} isEmbed={isEmbed} options={options} pymChild={this.props.pymChild} />}
      />
    ));

    /** Check if localStorage is accessible */
    const isStorageAvailable = () => {
      try {
        const check = typeof window == 'undefined' || !localStorage || !sessionStorage ? false : true;
        return check;
      } catch (e) {
        console.log('Cannot access localStorage or sessionStorage');
        return false;
      }
    };

    return isStorageAvailable() ? (
      <UserProvider>
        <DashboardProvider>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <I18nextProvider i18n={i18n}>
                <StateProvider>
                  <QueryClientProvider client={queryClient}>
                    <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
                      <ScrollToTop />
                      <Suspense fallback={<Loading />}>
                        <Switch>
                          {/* The following removes trailing slashes from the URL */}
                          <Redirect
                            from="/:url*(/+)"
                            to={
                              this.props.history.location.pathname.slice(0, -1) +
                              this.props.history.location.hash +
                              this.props.history.location.search
                            }
                          />

                          {baseRoutes}

                          {partnerRoutes}

                          {advisorRoutes}

                          {maRoutes}

                          {eRoutes}

                          {realtorRoutes}

                          {customerRoutes}

                          {marketingRoutes}

                          {realtyRoutes}

                          <Route exact path="/sign-in/">
                            <LoginLazy pymChild={this.props.pymChild} />
                          </Route>

                          <Route exact path="/register/">
                            <RegisterLazy pymChild={this.props.pymChild} />
                          </Route>

                          <Route exact path="/reset-password/">
                            <ResetPasswordLazy pymChild={this.props.pymChild} />
                          </Route>

                          <Route exact path="/verify/">
                            <VerifyEmailLazy pymChild={this.props.pymChild} />
                          </Route>

                          {/* Dashboard */}
                          <PrivateRoute
                            exact
                            path="/dashboard/"
                            component={() => {
                              return <DashboardLazy pymChild={this.props.pymChild} />;
                            }}
                          />

                          <PrivateRoute
                            exact
                            path="/dashboard/referrals/"
                            component={() => {
                              return <ReferralsLazy pymChild={this.props.pymChild} />;
                            }}
                          />

                          <PrivateRoute
                            exact
                            path="/dashboard/documents/"
                            component={() => {
                              return <DocumentsLazy pymChild={this.props.pymChild} />;
                            }}
                          />

                          <PrivateRoute
                            exact
                            path="/dashboard/applications/"
                            component={() => {
                              return <ApplicationsLazy pymChild={this.props.pymChild} />;
                            }}
                          />

                          <PrivateRoute
                            exact
                            path="/dashboard/documents/:id"
                            component={({ match }) => {
                              return <DocumentsLazy match={match} pymChild={this.props.pymChild} />;
                            }}
                          />

                          <PrivateRoute
                            exact
                            path="/dashboard/affordability-calculator/"
                            component={({ match }) => {
                              return <AffordabilityCalculatorLazy match={match} pymChild={this.props.pymChild} />;
                            }}
                          />

                          <PrivateRoute
                            exact
                            path="/dashboard/closing-costs-calculator/"
                            component={({ match }) => {
                              return <ClosingCostsCalculatorLazy match={match} pymChild={this.props.pymChild} />;
                            }}
                          />

                          <PrivateRoute
                            exact
                            path="/dashboard/mortgage-payment-calculator/"
                            component={({ match }) => {
                              return <MortgagePaymentCalculatorLazy match={match} pymChild={this.props.pymChild} />;
                            }}
                          />

                          <PrivateRoute
                            path="/dashboard/:id"
                            exact
                            component={({ match }) => {
                              return <StatusLazy match={match} pymChild={this.props.pymChild} />;
                            }}
                          />

                          <PrivateRoute
                            path="/dashboard/application/:id"
                            exact
                            component={({ match }) => {
                              return <StatusLazy match={match} pymChild={this.props.pymChild} />;
                            }}
                          />

                          <Route component={NotFound} />
                        </Switch>
                      </Suspense>
                    </BrowserRouter>
                  </QueryClientProvider>
                </StateProvider>
              </I18nextProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        </DashboardProvider>
      </UserProvider>
    ) : (
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <Landing pymChild={this.props.pymChild} isIncognito={true} />
            </BrowserRouter>
          </I18nextProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    );
  }
}
