import React, { useContext, useState, useEffect, lazy, Suspense } from 'react';

import { store } from '../../store/app/Store';
import { usePartnerLookup } from 'utils/hooks/usePartnerLookup';

import { withTranslation } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';

import Nav from 'components/Nav';
import Landing from 'pages/Landing/Landing';
import EmbedFooter from 'components/EmbedFooter';

import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useAppOptions from 'utils/hooks/useAppOptions';
import { saveQueryStrings } from 'utils/saveQueryStrings';

const Steps = lazy(() => import('pages/Landing/Steps'));

const Controller = (props) => {
  const { options, group, routeStep, alias1, alias2, isEmbed, appLoading } = props;

  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [fromLanding, setfromLanding] = useState(false);

  const [activeStep, setActiveStep] = useState(routeStep === 0 ? 1 : routeStep > 23 ? 1 : routeStep - 1);
  const history = useHistory();

  useAppOptions(history);

  usePartnerLookup(globalState, group, dispatch, alias1, alias2);

  useEffect(() => {
    const query = saveQueryStrings();
    if (query) sessionStorage.setItem('query', JSON.stringify(query));
  }, [])

  return (
    <>
      {!isEmbed && <Nav fromController={true} setActiveStep={setActiveStep} dispatch={dispatch} showFlag={true} />}

      {!!activeStep || activeStep === 0 ? (
        <Suspense
          fallback={
            <Container
              disableGutters
              style={{
                display: `flex`,
                flexDirection: `column`,
                flexGrow: `1`,
              }}
            >
              <Grid container>
                <Grid item xs={12} style={{ padding: `1.5rem 0` }}>
                  <div style={{ margin: `0 auto`, width: `50%` }}>
                    <LinearProgress
                      sx={{
                        margin: 0,
                        padding: 0,
                      }}
                      variant="determinate"
                      value={0}
                    />
                  </div>
                </Grid>
              </Grid>
            </Container>
          }
        >
          <Steps
            group={group}
            routeStep={routeStep}
            alias1={alias1}
            alias2={alias2}
            isEmbed={isEmbed}
            fromLanding={fromLanding}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Suspense>
      ) : (
        <>
          <Landing
            options={options}
            isEmbed={isEmbed}
            setActiveStep={setActiveStep}
            setfromLanding={setfromLanding}
            isPartner={group && alias1}
            appLoading={appLoading}
          />
          {isEmbed && <EmbedFooter />}
        </>
      )}
    </>
  );
};

export default withRouter(withTranslation('questions')(Controller));
