import { useEffect, useContext } from 'react';
import CryptoJS from 'crypto-js';
import { store } from 'store/app/Store';

const dot = require('dot-object');

const useAppOptions = (history) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  /**
   * This will take the app options data and set it to the global state's individual app-option properties.
   * @param {Object} currentAppOptions - App options data object.
   * @returns {void}
   */
  function setAppOptions(currentAppOptions) {
    /** 
     * This object contains the map from the data structured as per 
     * the UI schema to the individual store properties 
     */
    const uiToStoreDataMap = {
      'hide': 'appOptions.hide',
    }

    try {
      let appOptionsDataForStore = dot.transform(uiToStoreDataMap, currentAppOptions);

      /**
       * This will loop through the appDataForStore transformed object
       * & set the data from the currentAppOptions to the state values.
       */
      for (const [key, val] of Object.entries(appOptionsDataForStore)) {
        if (val === null) delete appOptionsDataForStore[key];
        else if (val === undefined) delete appOptionsDataForStore[key];
        else {
          const payload = {
            type: key,
            newVal: val
          };
          // This particular dispatch call 
          dispatch(payload);
        }
      }

      // This will set the app options data to the sessionStorage
      sessionStorage.setItem('hw_options', JSON.stringify(currentAppOptions));
    } catch (e) {
      console.log('setAppOptions error -->' ,e);
    }
  };

  useEffect(() => {
    /** The following if block is being used to set application data from the
     * queryString var {hw_options} this block is specifically built to accomodate
     * a partner request to send use data via a base64 encoded queryString var
     * & to prefill that in the app for easier CX.
     */
    const url = new URL(window.location.href);
    let getParam;
    
    if(url?.searchParams?.get('hw_options')) getParam = url.searchParams.get('hw_options');

    if (
      getParam &&
      getParam !== 'undefined'
    ) {
      try {
        const decodedData = CryptoJS.enc.Base64.parse(getParam),
          stringifiedData = CryptoJS.enc.Utf8.stringify(decodedData),
          parsedData = !!stringifiedData
            ? JSON.parse(decodeURIComponent(stringifiedData))
            : null;

        if (typeof parsedData === 'object') setAppOptions(parsedData);
      } catch (e) {
        console.log('setData from state queryString error -->', e);
      }

      return
    }

    // Logic for persisting the app options data throughout a session using data available in sessionStorage.
    // NOTE: This is skipped if there is a hw_options variable is available in the queryString.
    if (
      !getParam &&
      sessionStorage.getItem('hw_options')
    ) {
      try {
        const parsedData = JSON.parse(sessionStorage.getItem('hw_options'));

        if (typeof parsedData === 'object') setAppOptions(parsedData);
      } catch (e) {
        console.log('setData from state queryString error -->', e);
      }

      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
}

export default useAppOptions;