export function saveQueryStrings() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let params = {};

  for (const [key, value] of urlParams.entries()) {
    params[encodeURIComponent(key)] = encodeURIComponent(value);
  }

  // Also include the raw query string
  params.raw = queryString;

  return params;
}