import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import TagManager from 'react-gtm-module';

import { store } from '../../store/app/Store';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';

import greeting from '../../imgs/greetings.png';
import getstarted from '../../imgs/get-started-2.png';
import getstartedpartner from '../../imgs/get-started-partner.png';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import Fade from 'react-reveal/Fade';
import Loading from 'components/Loading2';
import config from '../../config';

import classes from 'styles/Landing.module.scss';

const Landing = (props) => {
  const { t, setActiveStep, options, setfromLanding, isPartner, isEmbed, appLoading } = props;
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const vals = {
    a: 'pre_approve',
    b: 'purchase',
    c: 'switch',
  };

  const FinanceA = withRouter(({ history }) => (
    <Button
      onClick={() => {
        dispatch({ type: 'financeType', newVal: 'a' });
        dispatch({ type: 'inProgress', newVal: true });

        TagManager.dataLayer({
          dataLayer: {
            loanType: vals['a'],
          },
        });

        const route = props.match.url;
        const updatedRoute = route.replace('options', 'step-2');
        props.history.push(updatedRoute + props?.history?.location?.search);
        setActiveStep(2);
        setfromLanding(true);
      }}
      variant={'contained'}
      color="primary"
      className={classes.btnOption}
      id="options-finance-a"
    >
      {t('questions:financeType.option.1.bold')}
    </Button>
  ));

  const FinanceB = withRouter(({ history }) => (
    <Button
      onClick={() => {
        dispatch({ type: 'financeType', newVal: 'b' });
        dispatch({ type: 'inProgress', newVal: true });

        TagManager.dataLayer({
          dataLayer: {
            loanType: vals['b'],
          },
        });

        const route = props.match.url;
        const updatedRoute = route.replace('options', 'step-2');
        props.history.push(updatedRoute + props?.history?.location?.search);
        setActiveStep(2);
        setfromLanding(true);
      }}
      variant={'contained'}
      color="primary"
      className={classes.btnOption}
      id="options-finance-b"
    >
      {t('questions:financeType.option.2.bold')}
    </Button>
  ));

  const FinanceC = withRouter(({ history }) => (
    <Button
      onClick={() => {
        dispatch({ type: 'financeType', newVal: 'c' });
        dispatch({ type: 'inProgress', newVal: true });

        TagManager.dataLayer({
          dataLayer: {
            loanType: vals['c'],
          },
        });

        const route = props.match.url;
        const updatedRoute = route.replace('options', 'step-2');
        props.history.push(updatedRoute + props?.history?.location?.search);
        setActiveStep(2);
        setfromLanding(true);
      }}
      variant={'contained'}
      color="primary"
      className={classes.btnOption}
      id="options-finance-c"
    >
      {t('questions:financeType.option.3.bold')}
    </Button>
  ));

  const pointForm = [1, 2, 3];

  // GTM dataLayer push for timer trigger
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        appName: 'Intro',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  return (
    <section className={isEmbed ? classes.embed : classes.hero}>
      <Container maxWidth="md">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              {!isEmbed ? (
                <Fade left>
                  <img src={greeting} width="100" height="79" alt="Hi!" loading="lazy" />
                </Fade>
              ) : (
                <img src={greeting} width="100" height="79" alt="Hi!" loading="lazy" />
              )}
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
              {!isEmbed ? (
                <Fade left>
                  <img src={greeting} width="70" height="55.44" alt="Hi!" loading="lazy" />
                </Fade>
              ) : (
                <img src={greeting} width="100" height="79" alt="Hi!" loading="lazy" />
              )}
            </Box>
            {!isEmbed ? (
              <>
                <Fade delay={600}>
                  <>
                    <h1
                      className="topTitle"
                      dangerouslySetInnerHTML={{
                        __html: t('title', {
                          start: "<span class='highlight'>",
                          end: '</span>',
                          interpolation: { escapeValue: false },
                        }),
                      }}
                    />
                  </>
                </Fade>
                <Fade delay={700}>
                  <p style={{ lineHeight: `1.6rem` }}>{t('sub')}</p>
                </Fade>
              </>
            ) : (
              <>
                <h1
                  className="topTitle"
                  dangerouslySetInnerHTML={{
                    __html: t('title', {
                      start: "<span class='highlight'>",
                      end: '</span>',
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
                <p style={{ lineHeight: `1.6rem` }}>{t('sub')}</p>
              </>
            )}
          </Grid>
        </Grid>
        {!options ? (
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <List component="ul">
                {!isEmbed ? (
                  <Fade delay={700}>
                    {pointForm.map((item) => (
                      <ListItem key={item} alignItems="flex-start" disableGutters style={{ paddinTop: `0px` }}>
                        <ListItemIcon style={{ marginTop: '.2rem', minWidth: `auto`, flexShrink: `unset`, paddingRight: `.5rem` }}>
                          <CheckCircleOutlineIcon color="secondary" style={{ fontSize: '1.25rem' }} />
                        </ListItemIcon>
                        <p className={classes.points}>
                          <strong>{t('steps.' + item + '.bold')}</strong> {t('steps.' + item + '.copy')}
                        </p>
                      </ListItem>
                    ))}
                  </Fade>
                ) : (
                  pointForm.map((item) => (
                    <ListItem key={item} alignItems="flex-start" disableGutters style={{ paddinTop: `0px` }}>
                      <ListItemIcon style={{ marginTop: '.2rem', minWidth: `auto`, flexShrink: `unset`, paddingRight: `.5rem` }}>
                        <CheckCircleOutlineIcon color="secondary" style={{ fontSize: '1.25rem' }} />
                      </ListItemIcon>
                      <p className={classes.points}>
                        <strong>{t('steps.' + item + '.bold')}</strong> {t('steps.' + item + '.copy')}
                      </p>
                    </ListItem>
                  ))
                )}
              </List>
              {!isEmbed ? (
                <Fade delay={800}>
                  {appLoading ? (
                    <Loading />
                  ) : // Opens app in a new window if the instance is in a incognito browser window.
                  props?.isIncognito ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnOption}
                      style={{ background: `#147AC8` }}
                      id="btnGetStarted"
                      href={`${config.hoa}${props.location.pathname
                        .toString()
                        .replace(/(?:\/+(\?))/, '$1')
                        .replace(/\/+$/, '')}/step-1${props?.history?.location?.search}`}
                      target="_blank"
                      disabled={appLoading}
                    >
                      {t('items.0.title1')}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnOption}
                      style={{ background: `#147AC8` }}
                      id="btnGetStarted"
                      onClick={() => {
                        const route = props.match.url;
                        const updatedRoute = route === '/' ? route + `step-1` : route + `/step-1`;
                        props.history.push(updatedRoute + props?.history?.location?.search);
                        setActiveStep(1);
                        setfromLanding(true);
                      }}
                      disabled={appLoading}
                    >
                      {t('items.0.title1')}
                    </Button>
                  )}
                </Fade>
              ) : (
                <>
                  {appLoading ? (
                    <Loading />
                  ) : // Opens app in a new window if the instance is in a incognito browser window.
                  props?.isIncognito ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnOption}
                      style={{ background: `#147AC8` }}
                      id="btnGetStarted"
                      href={`${config.hoa}${props.location.pathname
                        .toString()
                        .replace(/(?:\/+(\?))/, '$1')
                        .replace(/\/+$/, '')}/step-1${props?.history?.location?.search}`}
                      target="_blank"
                      disabled={appLoading}
                    >
                      {t('items.0.title1')}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnOption}
                      style={{ background: `#147AC8` }}
                      id="btnGetStarted"
                      onClick={() => {
                        const route = props.match.url;
                        const updatedRoute = route === '/' ? route + `step-1` : route + `/step-1`;
                        props.history.push(updatedRoute + props?.history?.location?.search);
                        setActiveStep(1);
                        setfromLanding(true);
                      }}
                      disabled={appLoading}
                    >
                      {t('items.0.title1')}
                    </Button>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              {!isEmbed ? (
                <Fade delay={800}>
                  <img
                    src={isPartner ? getstartedpartner : getstarted}
                    alt="Get Started with Homewise"
                    width="280"
                    className={classes.modelHome}
                    loading="lazy"
                  />
                </Fade>
              ) : (
                <img
                  src={isPartner ? getstartedpartner : getstarted}
                  alt="Get Started with Homewise"
                  width="280"
                  className={classes.modelHome}
                  loading="lazy"
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <Grid item xs={12} md={5}>
              <List component="ul">
                {!isEmbed ? (
                  <Fade delay={700}>
                    {pointForm.map((item) => (
                      <ListItem key={item} alignItems="flex-start" disableGutters style={{ paddinTop: `0px` }}>
                        <ListItemIcon style={{ marginTop: '.2rem', minWidth: `auto`, flexShrink: `unset`, paddingRight: `.5rem` }}>
                          <CheckCircleOutlineIcon color="secondary" />
                        </ListItemIcon>
                        <p className={classes.points}>
                          <strong>{t('steps.' + item + '.bold')}</strong> {t('steps.' + item + '.copy')}
                        </p>
                      </ListItem>
                    ))}
                  </Fade>
                ) : (
                  pointForm.map((item) => (
                    <ListItem key={item} alignItems="flex-start" disableGutters style={{ paddinTop: `0px` }}>
                      <ListItemIcon style={{ marginTop: '.2rem', minWidth: `auto`, flexShrink: `unset`, paddingRight: `.5rem` }}>
                        <CheckCircleOutlineIcon color="secondary" />
                      </ListItemIcon>
                      <p className={classes.points}>
                        <strong>{t('steps.' + item + '.bold')}</strong> {t('steps.' + item + '.copy')}
                      </p>
                    </ListItem>
                  ))
                )}
              </List>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <Fade delay={800}>
                <img
                  src={isPartner ? getstartedpartner : getstarted}
                  alt="Get Started with Homewise"
                  width="280"
                  className={classes.modelHome}
                  loading="lazy"
                />
              </Fade>
            </Grid>
            <Grid item xs={12}>
              {appLoading ? (
                <Loading />
              ) : (
                <div className={classes.buttonGrid}>
                  <Fade up delay={900}>
                    <FinanceA />
                  </Fade>
                  <Fade up delay={1000}>
                    <FinanceB />
                  </Fade>
                  <Fade up delay={1100}>
                    <FinanceC />
                  </Fade>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Fade delay={1300}>
                <p className="legal" style={{ marginTop: `1rem` }}>
                  {t('legal')}
                </p>
              </Fade>
            </Grid>
          </Grid>
        )}
      </Container>
    </section>
  );
};

export default withRouter(withTranslation('getstarted')(Landing));
