// This file consists of the API calls to the customer API

import axios from './config';
import Config from '../config';

// Base API route definition
const baseUrl = Config.api.BASE,
  group = 'homewise';

/**
 * getSchemaUrls call to fetch schemas from the microservice
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const getSchemaUrls = async (token) => {
  return await axios({
    token,
    method: 'GET',
    baseURL: baseUrl,
    url: '/meta',
    params: {
      group,
    },
  });
};

/**
 * getApp API call to fetch an application from the microservice
 * @param {String} appId
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const getApp = async (appId, token) => {
  return await axios({
    token,
    method: 'GET',
    baseURL: baseUrl,
    url: `/application/${appId}`,
  });
};

/**
 * getUser API call to fetch user data from the microservice
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const getUser = async (token) => {
  return await axios({
    token,
    method: 'GET',
    baseURL: baseUrl,
    url: '/user',
  });
};

/**
 * createUser API call to create a new user in the microservice
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const createUser = async (data, token) => {
  return await axios({
    token,
    method: 'POST',
    baseURL: baseUrl,
    url: '/user',
    data: data,
  });
};

/**
 * postApp API call to post a new application to the microservice
 * @param {String} userId - user id
 * @param {Object} data - application data
 * @returns {Promise}
 */
const createApp = async (userId, data) => {
  return await axios({
    method: 'POST',
    baseURL: baseUrl,
    url: '/application',
    data: data,
    headers: {
      'user-id': userId,
    },
  });
};

/**
 * updateApp API call to update an application in the microservice
 * @param {String} appId - application id
 * @param {Object} data - application data
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const updateApp = async (appId, data, token) => {
  return await axios({
    token,
    method: 'PUT',
    baseURL: baseUrl,
    url: `/application/${appId}`,
    data: data,
  });
};

/**
 * updateUser API call to update a user in the microservice
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const updateUser = async (data, token) => {
  return await axios({
    token,
    method: 'PUT',
    baseURL: baseUrl,
    url: '/user',
    data: data,
  });
};

/**
 * createSigner API call to create a new signer in the microservice
 * @param {String} appId - application id
 * @param {Object} data - signer data
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const createSigner = async (appId, data, token) => {
  return await axios({
    token,
    method: 'POST',
    baseURL: baseUrl,
    url: `/application/${appId}/signer`,
    data: data,
  });
};

/**
 * updateSigner API call to update a signer in the microservice
 * @param {String} appId - application id
 * @param {String} signerId - signer id
 * @param {Object} data - signer data
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const updateSigner = async (appId, signerId, data, token) => {
  return await axios({
    token,
    method: 'PUT',
    baseURL: baseUrl,
    url: `/application/${appId}/signer/${signerId}`,
    data: data,
  });
};

/**
 * checkEmail API call to check if an email is already in use in the microservice
 * @param {String} email - email to check
 * @param {String} type - type of email to check
 * @param {String} token - authentication token
 * @returns {Object} data - The validation result
 */
const checkEmail = async (email, type) => {
  return await axios({
    method: 'POST',
    baseURL: baseUrl,
    url: '/user/email-check',
    data: {
      email,
      type,
    },
  });
};

/**
 * getDashboard API call to fetch the dashboard data from the microservice
 * @param {String} appId - application id
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const getDashboard = async (token) => {
  return await axios({
    token,
    method: 'GET',
    baseURL: baseUrl,
    url: `/dashboard`,
  });
};

/**
 * getDocumentCategories API call to fetch the document categories from the microservice
 * @param {String} appId
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const getDocumentCategories = async (appId, token) => {
  return await axios({
    token,
    method: 'GET',
    baseURL: baseUrl,
    url: `/application/${appId}/documents`,
  });
};

/**
 * getSignedUrls API call to fetch the AWS S3 signed urls for document upload from the microservice
 * @param {String} appId - application id
 * @param {data} data - documents data that need upload URLs
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const getSignedUrls = async (appId, data, token) => {
  return await axios({
    token,
    method: 'POST',
    baseURL: baseUrl,
    url: `/application/${appId}/documents/upload-url`,
    data,
  });
};

/**
 * getMatch API call to fetch the match data from the microservice
 * @param {String} appId
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const getMatch = async (appId, token) => {
  return await axios({
    token,
    method: 'GET',
    baseURL: baseUrl,
    url: `/application/${appId}/match`,
  });
};

/**
 * setMatchPreference API call to set the match preference in the microservice
 * @param {String} appId - application id
 * @param {String} matchId - match id
 * @param {String} token - authentication token
 * @param {Object} data - match preference data
 * @returns {Promise}
 */
const setMatchPreference = async (appId, matchId, data, token) => {
  return await axios({
    token,
    method: 'PUT',
    baseURL: baseUrl,
    url: `/application/${appId}/match/${matchId}`,
    data,
  });
};

/**
 * getReferrals API call to fetch the referrals data from the microservice
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const getReferrals = async (token) => {
  return await axios({
    token,
    method: 'GET',
    baseURL: baseUrl,
    url: `/user/referrals`,
  });
};

/**
 * getOffers API call to fetch the offers data from the microservice
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const getOffers = async (appId, token) => {
  return await axios({
    token,
    method: 'GET',
    baseURL: baseUrl,
    url: `/application/${appId}/offers`,
  });
};

/**
 * Offers Action API call to the microservice
 * @param {String} method - offer based method
 * @param {String} uri - offer based uri
 * @param {String} id - application id
 * @param {Object} data - offer data
 * @param {String} token - authentication token
 * @returns {Promise}
 */
const offerAction = async (method, uri, id, data, token) => {
  return await axios({
    token,
    method: method,
    baseURL: Config.api.API_HOST,
    url: uri,
    data: {
      bId: id,
      offer: data,
    },
  });
};

const customerApi = {
  getSchemaUrls: getSchemaUrls,
  getApp: getApp,
  getUser: getUser,
  createUser: createUser,
  createApp: createApp,
  updateApp: updateApp,
  updateUser: updateUser,
  createSigner: createSigner,
  updateSigner: updateSigner,
  checkEmail: checkEmail,
  getDashboard: getDashboard,
  getSignedUrls: getSignedUrls,
  getDocumentCategories: getDocumentCategories,
  getMatch: getMatch,
  setMatchPreference: setMatchPreference,
  getReferrals: getReferrals,
  getOffers: getOffers,
  offerAction: offerAction,
};

export default customerApi;
