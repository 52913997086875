import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import TermsOfUseContent from './TermsOfUseContent';
import PrivacyPolicyContent from './PrivacyPolicyContent';

import classes from 'styles/components/EmbedFooter.module.scss';
import PoweredBy from './PoweredBy';

const EmbedFooter = (props) => {
  const { t } = useTranslation('common');
  const [openTOU, setOpenTOU] = useState(false);
  const [openPP, setOpenPP] = useState(false);

  const handleOpenTOU = () => setOpenTOU(true);
  const handleCloseTOU = () => setOpenTOU(false);

  const handleOpenPP = () => setOpenPP(true);
  const handleClosePP = () => setOpenPP(false);
  return (
    <Container disableGutters>
      <Grid container direction="row" justifyContent="center">
        <Grid item container sm={8} xs={12}>
          <Grid item xs={12} sm={6}>
            <p className={classes.linkGroup}>
              <Button
                className={classes.link}
                style={{
                  textDecoration: `none`,
                  background: `transparent`,
                  border: `0`,
                  cursor: `pointer`,
                  fontFamily: `Merriweather Sans`,
                  padding: `0`,
                  textTransform: `capitalize`,
                }}
                onClick={handleOpenTOU}
              >
                {t('cta.terms')}
              </Button>
              &nbsp; &nbsp;
              <Button
                className={classes.link}
                style={{
                  textDecoration: `none`,
                  background: `transparent`,
                  border: `0`,
                  cursor: `pointer`,
                  fontFamily: `Merriweather Sans`,
                  padding: `0`,
                  textTransform: `capitalize`,
                }}
                onClick={handleOpenPP}
              >
                {t('cta.privacy')}
              </Button>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PoweredBy />
          </Grid>
        </Grid>
      </Grid>
      <Modal open={openTOU} onClose={handleCloseTOU}>
        <Box className={classes.modalBox}>
          <div className={classes.modalContent}>
            <div className={classes.modalTitle}>
              <h2>Terms of Use</h2>
              <IconButton onClick={handleCloseTOU}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.modalBody}>
              <TermsOfUseContent />
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={openPP} onClose={handleClosePP}>
        <Box className={classes.modalBox}>
          <div className={classes.modalContent}>
            <div className={classes.modalTitle}>
              <h2>Privacy Policy</h2>
              <IconButton onClick={handleClosePP}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.modalBody}>
              <PrivacyPolicyContent />
            </div>
          </div>
        </Box>
      </Modal>
    </Container>
  );
};

export default EmbedFooter;
