import { useEffect } from 'react';
import Config from '../../config';
import isEmpty from 'lodash/isEmpty';

export function usePartnerLookup(globalState, group, dispatch, alias1, alias2) {
  useEffect(() => {
    const url = new URL(window.location.href);
    let businessName = '',
      localReferrer = globalState.state.partner,
      isPartnerValid = false;

    const hw_product = url.searchParams.get('hw_product'),
      hw_segment = url.searchParams.get('hw_segment'); //eslint-disable-line

    /** The group types for which partner api calls can be skipped. */
    const noPartnerCallGroup = ['customer', 'marketing', 'ma', 'ra', 'e'];

    if (!!hw_segment && hw_segment !== '' && hw_segment !== undefined) {
      // eslint-disable-next-line
      alias2 = hw_segment;
    }

    if (!globalState.state.isPartnerValid) {
      const url = new URL(window.location.href);

      if (group && !noPartnerCallGroup.includes(group)) {
        dispatch({ type: 'isPartnerValid', newVal: true });
        isPartnerValid = true;
      }

      // Do this if the group is present & the partnerType is valid
      if (group && isPartnerValid) {
        // Dispatch all values to store
        dispatch({ type: 'partnerPath', newVal: url.pathname });
        dispatch({ type: 'group', newVal: group });
        dispatch({ type: 'alias1', newVal: alias1.split('.').join('-') });

        if (alias2) dispatch({ type: 'alias2', newVal: alias2.split('.').join('-') });

        if (hw_product) dispatch({ type: 'product', newVal: hw_product });

        // Get the partner data if not already present in state
        if (!localReferrer.business) {
          async function getPartner(alias, group) {
            try {
              const response = await fetch(`${Config.api.PARTNER}/${alias.split('.').join('-')}/?group=${group}`);
              const data = await response.json();

              if (response.status >= 400) {
                dispatch({ type: 'isPartnerValid', newVal: true }); // signals logic to rerun in stepper
                return { status: response.status };
              } else {
                return { status: response.status, data: data };
              }
            } catch (error) {
              console.error('Error fetching partner data:', error);
              return { status: 'error', error };
            }
          }

          const needsAsync = async () => {
            await getPartner(alias1, group)
              .then((res) => {
                if (res.status >= '400') {
                  localReferrer = null;
                  let referrer = {
                    alias1: alias1.split('.').join('-'),
                    type: group,
                  };

                  if (alias2) Object.assign(referrer, { alias2: alias2.split('.').join('-') });

                  if (hw_product) Object.assign(referrer, { product: hw_product });

                  dispatch({
                    type: 'referrer',
                    newVal: referrer,
                  });

                  let newApp = Object.assign(globalState.state.app, {
                    referrer: referrer,
                  });
                  dispatch({ type: 'app', newVal: newApp });
                } else {
                  dispatch({ type: 'partner', newVal: res.data });
                  localReferrer = res.data;
                  businessName = res.data ? (res.data.business ? (res.data.business.name ? res.data.business.name : null) : null) : null;

                  // Get the tracking params & assign the values to the referrer.tracking obj
                  let toBeTracked = {};

                  if (!isEmpty(localReferrer) && isEmpty(globalState.state.uiTracking)) {
                    if (!isEmpty(localReferrer.tracking)) {
                      if (localReferrer.tracking.length !== 0) {
                        const whatToTrack = localReferrer.tracking;
                        whatToTrack.forEach((param) => {
                          const searchParams = url.searchParams,
                            getParam = searchParams.get(param);

                          toBeTracked[param] = getParam;
                        });
                        dispatch({ type: 'uiTracking', newVal: toBeTracked });
                      }
                    }
                  }

                  let referrer = {
                    alias1: alias1.split('.').join('-'),
                    type: group,
                    tracking: toBeTracked,
                  };

                  if (businessName) Object.assign(referrer, { name: businessName });

                  if (alias2) Object.assign(referrer, { alias2: alias2.split('.').join('-') });

                  if (hw_product) Object.assign(referrer, { product: hw_product });

                  dispatch({
                    type: 'referrer',
                    newVal: referrer,
                  });

                  let newApp = Object.assign(globalState.state.app, {
                    referrer: referrer,
                  });
                  dispatch({ type: 'app', newVal: newApp });
                }
              })
              // Catch any errors we hit and update the app
              .catch((err) => {});
          };
          needsAsync();
        }
      }

      const isReferred = Object.keys(globalState.state.referrer).length > 0;
      if (noPartnerCallGroup.includes(group) && !isReferred) {
        dispatch({ type: 'group', newVal: group });
        dispatch({ type: 'alias1', newVal: alias1.split('.').join('-') });

        if (alias2) dispatch({ type: 'alias2', newVal: alias2.split('.').join('-') });

        if (hw_product) dispatch({ type: 'product', newVal: hw_product });

        let referrer = {
          alias1: alias1.split('.').join('-'),
          type: group,
        };

        if (alias2) {
          Object.assign(referrer, { alias2: alias2.split('.').join('-') });
          // Object.assign(referrer, { product: alias2 });
        }

        if (hw_product) Object.assign(referrer, { product: hw_product });

        dispatch({
          type: 'referrer',
          newVal: referrer,
        });

        let newApp = Object.assign(globalState.state.app, { referrer: referrer });
        dispatch({ type: 'app', newVal: newApp });
        dispatch({ type: 'referrer', newVal: referrer });
      }
    }
  });
}
