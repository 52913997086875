import React from 'react';
import cmsData from '../cmsData.json';

export default function TermsOfUseContent() {
  if (!cmsData) {
    console.error('Error: cmsData.json could not be found or loaded.');
    return <div>Terms of use information is currently unavailable.</div>;
  }

  const { terms } = cmsData;

  if (!Array.isArray(terms)) {
    console.error(`Error: Expected 'terms' to be an array, but received ${typeof terms}.`);
    return <div>Terms of use information is currently unavailable.</div>;
  }

  if (terms.length === 0) {
    console.warn('Warning: The terms array is empty.');
    return <div>No terms of use available at this time.</div>;
  }

  return (
    <div>
      {terms.map((page) =>
        page.title && page.body ? (
          <div key={page.title}>
            <div dangerouslySetInnerHTML={{ __html: page.body }} />
          </div>
        ) : (
          <div key={page.title || Math.random()}>
            <p>Invalid terms of use page data.</p>
          </div>
        )
      )}
    </div>
  );
}
