import React from 'react';
import { Translation } from 'react-i18next';

import MainLayout from '../components/layouts/Main';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import pups from '../imgs/group-portrait-adorable-puppies.jpg';

const NotFound = (props) => {
  const theme = useTheme();
  return (
    <MainLayout showFlag={true}>
      <section style={{ padding: `10px 0px ` }}>
        <Container maxWidth="md">
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Paper
                sx={{
                  textAlign: 'left',
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(3),
                  padding: `2em 1em 2.5em`,
                  boxShadow: `0 2px 2px rgba(0,0,0,.15), inset 0 -2px 0 #ccefc4`,
                  [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
                    marginTop: theme.spacing(6),
                    marginBottom: theme.spacing(3),
                    padding: theme.spacing(3),
                  },
                }}
              >
                <Grid item xs={12} style={{ padding: `0 24px` }}>
                  <Translation>{(t) => <h2 style={{ marginTop: `0px` }}>{t('common:notfound.title')}</h2>}</Translation>
                  <Translation>{(t) => <p>{t('common:notfound.tagline')}</p>}</Translation>
                  <Grid container style={{ paddingTop: `2rem` }}>
                    <Button variant="contained" color="primary" href="../">
                      <Translation>{(t) => <span>{t('common:notfound.cta')}</span>}</Translation>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </section>
      <img
        src={pups}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: '-1',
          objectFit: 'cover',
          objectPosition: 'center center',
        }}
        alt=""
      />
    </MainLayout>
  );
};

export default NotFound;
